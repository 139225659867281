import { Modal } from "@goalteller/app-kit/wo-library/components/atoms";
import Link from "next/link";

import betaConfirmationImage from "../images/beta-confirmation.svg";
import AmpImage from "./AmpImage";
import styles from "./newsletterModal.module.css";

export default function NewsletterModal({
  setShowSuccess,
  success,
}: {
  setShowSuccess: (value: boolean) => void;
  success: boolean;
}) {
  return (
    <Modal
      isOpen={success}
      onClose={() => {
        setShowSuccess(false);
      }}
    >
      <div className={styles.betaConfirmation}>
        <div className={styles.title}>
          Welcome to GoalTeller&apos;s newsletter and Insights
        </div>
        <p className={styles.body}>
          We are confident that we will engage and enlighten you with
          interesting and relevant data and news every week. <br />
          You can also go to our Blog page to see earlier newsletters, insights,
          articles and more
        </p>

        <AmpImage alt="" src={betaConfirmationImage} width={300} />

        <Link
          className={styles.blogButton}
          href="/blog"
          onClick={() => {
            setShowSuccess(false);
          }}
        >
          Explore Blogs
        </Link>
      </div>
    </Modal>
  );
}
